import React from 'react';
import './About.scss';
import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';

const About = () => {
  return (
    <div className="about-me-container">
      <div className="about-me-left">
        <div className="image-container">
          <img src={images.profile2} alt="Picture of Me" />
        </div>
      </div>
      <div className="about-me-right">
        <p>
          I aspire to work with innovative and talented individuals 
          to develop impactful solutions to real-world problems. 
          As a motivated and fast learner, I can provide value to any team
          through my proclivity to rapidly adapt to new technologies and
          environments.
        </p>
        <p>
          With my continually improving technical skills and
          my proactive approach to problem-solving, I am
          confident in my ability to contribute to complex and meaningful projects. 
        </p>
        <p>
          Beyond coding and engineering, I am an avid chess, 
          basketball, and guitar player, who loves building a sense 
          of community everywhere I go.
        </p>
        {/* <a href={images.resume} download="Matthew So - Resume.pdf">
          <button className="button-class">
            Download Resume <img className="doc-svg" src={images.document} alt="SVG" />
          </button>
        </a> */}
      </div>
    </div>
  );
};

export default AppWrap(
  MotionWrap(About, 'app__about'),
  'about',
  'app__whitebg',
);
