import React, { useState, useEffect, useRef } from 'react';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { client } from '../../client';
import './Testimonial.scss';

const Testimonial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [testimonials, setTestimonials] = useState([]);
  const testimonialContainerRef = useRef(null);

  useEffect(() => {
    const query = '*[_type == "testimonials"]';

    client.fetch(query).then((data) => {
      setTestimonials(data);
    });
  }, []);

  const testimonialsToShow = () => {
    if (window.innerWidth >= 1200) {
      return 3; // Show three testimonial items by default
    } else if (window.innerWidth >= 768) {
      return 2; // Show two testimonial items if there is limited space
    } else {
      return 1; // Show one testimonial item if there is not enough space
    }
  };

  const scrollLeft = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const scrollRight = () => {
    if (currentIndex < testimonials.length - testimonialsToShow()) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <div className="testimonial-carousel">

      <h2 className="testimonial-header">What My <span>Friends Think About Me</span></h2>

      <div className="testimonial-slider">
        <div
          className={`testimonial-container ${currentIndex === 0 && 'first'}`}
          ref={testimonialContainerRef}
        >
          {testimonials.slice(currentIndex, currentIndex + testimonialsToShow()).map((testimonial, index) => (
            <div className="testimonial-item" key={`testimonial-${index}`}>
              <div className="testimonial-content">
                <p className="p-text">{testimonial.feedback}</p>
                <div>
                  <h4 className="bold-text">{testimonial.name}</h4>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="testimonial-controls">
        <div className={`testimonial-arrow ${currentIndex === 0 && 'disabled'}`} onClick={scrollLeft}>
          <HiChevronLeft />
        </div>
        <div className={`testimonial-arrow ${currentIndex === testimonials.length - testimonialsToShow() && 'disabled'}`} onClick={scrollRight}>
          <HiChevronRight />
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
