import React, { useState, useEffect } from 'react';

import { About, Footer, Header, Skills, Testimonial, Projects} from './container';
import { Navbar, PreLoader } from './components';
import './App.scss';

const App = () => {
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setPageLoaded(true);
    }, 2000);
  }, []);

  return (
    <div className = "app"> 
     {pageLoaded ? (
        <>
          <Navbar />
          <Header />
          <About />
          <Projects />
          <Skills />
          <Testimonial />
          <Footer />
        </>
      ) : (
        <PreLoader />
      )}
    </div>
  );
}

export default App;