import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import './PreLoader.css'

function PreLoader() {
  const [data, setData] = useState([]);
  const [done, setDone] = useState(undefined);

  useEffect(() => {
    setTimeout(() => {
      fetch("https://jsonplaceholder.typicode.com/posts")
        .then((response) => response.json())
        .then((json) => {
          console.log(json);
          setData(json);
          setDone(true);
        });
    }, 2000);
  }, []);

  return (
    <>
      <div className="centered-container">
        {!done ? (
          <ReactLoading type={"spin"} color={'#ADD8E6'} height={'20rem'} width={'20rem'} />
        ) : (
          <ul>
            {data.map((post) => (
              <li key={post.id}>{post.title}</li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
}

export default PreLoader;