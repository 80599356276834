import React from 'react';
import {
  AiFillLinkedin,
  AiFillGithub,
  AiOutlineWhatsApp,
} from "react-icons/ai";

const SocialMedia = () => (
  <div className="app__social">
    <div>
      <a href="https://www.linkedin.com/in/matthewdso/">
        <AiFillLinkedin/>
      </a>
    </div>
    <div>
      <a href="https://github.com/matthewd-so">
        <AiFillGithub/>
      </a>
    </div>
    <div>
      <a href="http://wasap.my/16478650319">  
        <AiOutlineWhatsApp />
      </a>
    </div>
  </div>
);

export default SocialMedia;
