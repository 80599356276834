import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import './Footer.scss';

const Footer = () => {
  const form = useRef();
  const [isEmailSent, setIsEmailSent] = useState(false); // State to track if the email is sent successfully

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_1er20jk', 'template_i56u2gd', form.current, 'Nv9jkycgXBqPI5cH6')
      .then((result) => {
        console.log(result.text);
        setIsEmailSent(true); // Set the state to true when the email is successfully sent
      })
      .catch((error) => {
        console.log(error.text);
      });
  };

  return (
    <>
      <h2 className="head-text">Get In <span>Touch With Me</span></h2>

      <div className="app__footer-cards">
        <div className="app__footer-card">
          <img src={images.email} alt="email" />
          <a href="mailto:so.matthewd@gmail.com" className="p-text">so.matthewd@gmail.com</a>
        </div>
        <div className="app__footer-card">
          <img src={images.mobile} alt="phone" />
          <a href="tel:+1 (647) 865 0319" className="p-text">+1 (647) 865 0319</a>
        </div>
      </div>
      <div className="app__footer-form app__flex">
        {isEmailSent ? (
          <div>
            <h3 className="head-text">
              Thank you for getting in touch!
            </h3>
        </div>
        ) : (
          <form className="app__contact-form" ref={form} onSubmit={sendEmail}>
            <label className="app__form-label">Name</label>
            <input className="app__form-input" type="text" name="user_name" />
            <label className="app__form-label">Email</label>
            <input className="app__form-input" type="email" name="user_email" />
            <label className="app__form-label">Message</label>
            <textarea className="app__form-textarea" name="message" />
            <input className="app__form-button" type="submit" value="Send" />
          </form>
        )}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__whitebg',
);
